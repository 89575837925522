import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import TrueLinkCopyIcon from 'react/shared/components/true_link/main/TrueLinkCopyIcon';

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
});

function TrueLinkSimpleLabel({ label, value, copyValue = null, multiLine = false, style }) {
  const classes = useStyles();
  const valueRef = useRef(null);

  const copyIcon = <TrueLinkCopyIcon copyValue={copyValue ?? valueRef} />;
  const canCopy = copyValue || value;

  return (
    <div style={style}>
      {multiLine ? (
        <>
          <span className={classes.label}>
            {label}: {canCopy && copyIcon}
          </span>
          <br />
          <span ref={valueRef} style={{ whiteSpace: 'pre-wrap' }}>
            {value}
          </span>
        </>
      ) : (
        <>
          <span className={classes.label}>{label}:</span>
          <span ref={valueRef}>{value}</span>
          {canCopy && copyIcon}
        </>
      )}
    </div>
  );
}

TrueLinkSimpleLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]),
  copyValue: PropTypes.string,
  style: PropTypes.object,
  multiLine: PropTypes.bool,
};

export default TrueLinkSimpleLabel;

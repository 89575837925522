import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import _debounce from 'underscore/modules/debounce';
import noop from 'underscore/modules/noop';
import FirstTransferPromptModal from 'react/member/card/components/funding_tab/bank_accounts/FirstTransferPromptModal';
import { DashboardCardContext } from 'react/member/card/components/onboarding_widget/DashboardCardContext';
import CardActivationModal from 'react/member/card/components/onboarding_widget/modals/card_activation/CardActivationModal';
import IdvModal from 'react/member/card/components/onboarding_widget/modals/card_idv/IdvModal';
import {
  PRE_RELEASE_STATUS,
  RELEASED_STATUS,
  estimatedArrivalShape,
} from 'react/member/shapes/estimated_arrival_shape';
import DashboardBankAccountStore from 'react/member/stores/DashboardBankAccountStore';
import DashboardTransferStore from 'react/member/stores/DashboardTransferStore';
import DashboardCardActivationForm from 'react/shared/card/components/DashboardCardActivationForm';
import BulkCardOrderConfirmationBox from 'react/shared/card/components/bulk_card_ordering/BulkCardOrderConfirmationBox';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkNotification from 'react/shared/components/true_link/main/TrueLinkNotification';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';
import {
  getProgramNameArticle,
  isAbleActProgramAccount,
} from 'react/shared/utils/account_program_type';

const useStyles = makeStyles(() => ({
  onboardingWidget: {
    backgroundColor: PALETTE.grey,
  },

  onboardingWidgetInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '940px',
    minWidth: '938px',
    paddingBottom: '28px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      minWidth: '375px',
      gap: '20px',
    },
  },

  onboardingWidgetCloseBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    color: PALETTE.grey4,
    fontSize: '30px',
    position: 'absolute',
    top: '-5px',
    right: '-25px',
    [theme.breakpoints.down('md')]: {
      right: '4px',
    },
  },

  onboardingWidgetLeft: {
    width: '330px',
    fontSize: '20px',

    [theme.breakpoints.down('md')]: {
      width: '340px',
    },
  },
  onboardingWidgetRight: {
    width: '600px',

    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  onboardingWidgetCard: {
    textAlign: 'left',
    outline: `1px solid ${PALETTE.grey4}`,
    borderRadius: '10px',
    boxShadow: ' 0px 4px 14px 0px rgba(0, 0, 0, 0.15)',
  },
  onboardingWidgetStep: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '10px',
    padding: '5px 10px',

    '&:not(:last-child)': {
      marginBottom: '10px',
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: PALETTE.emeraldLight,
    },
  },
  onboardingWidgetStepText: {
    display: 'inline-block',
    maxWidth: '90%',
    marginLeft: '20px',
    fontSize: ' 21px',
    lineHeight: '23px',
    position: 'relative',

    '& a': {
      '&:focus': {
        outline: 'none',
      },
      '&.failed': {
        color: PALETTE.orangeDark,
      },
    },

    '&.complete': {
      '& a': {
        color: PALETTE.emerald,
      },
      color: PALETTE.emerald,
    },
  },

  onboardingWidgetStepCheck: {
    marginTop: '0px',
    minWidth: '30px',
    borderRadius: '20px',
    outline: `1px solid ${PALETTE.grey3}`,
    '&.complete': {
      backgroundColor: PALETTE.emerald,
    },
  },
  helperText: {
    paddingBottom: '10px',
    paddingLeft: '10px',
  },
}));

export default function OnboardingWidget(props) {
  const classes = useStyles();
  const { setAccountId } = useContext(DashboardCardContext);

  const [onboardingFlow, setOnboardingFlow] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [idvModalOpen, setIdvModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [firstTransfeModalOpen, setFirstTransfeModalOpen] = useState(false);
  const [idvFailedFor18OrOver, setIdvFailedFor18OrOver] = useState(
    onboardingFlow && onboardingFlow?.idvFailed && onboardingFlow?.idvAgeOfPerson >= 18,
  );

  useEffect(() => {
    if (onboardingFlow?.idvFailed && onboardingFlow?.idvAgeOfPerson >= 18) {
      setIdvFailedFor18OrOver(true);
    }
  }, [onboardingFlow]);

  const getOnboardingFlowPath = () =>
    RailsRoutes.api_v2_account_account_onboarding_flow_path(props.accountId, {
      format: 'json',
    });

  const loadOnboardingFlow = () => {
    const url = getOnboardingFlowPath();
    axios.get(url, AXIOS_XHR_CONFIG).then(({ data }) => setOnboardingFlow(data?.data?.attributes));
  };

  const shouldHideWidget = () =>
    !onboardingFlow || onboardingFlow.fundingCompleted || onboardingFlow.bannerDismissed;

  const reloadOnboardingFlow = useCallback(() => {
    if (!shouldHideWidget()) {
      loadOnboardingFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideWidget]);

  const handleTransferLinkClick = () => {
    $('button#funding_tab_link').trigger('click');
    setFirstTransfeModalOpen(true);
  };
  const openConnectBankModelOnFundingTab = () => {
    $('button#funding_tab_link').trigger('click');
    window.setTimeout(() => $('#connect_bank_account_btn').trigger('click'), 1000);
  };

  const openVerifyAccountModelOnFundingTab = () => {
    $('button#funding_tab_link').trigger('click');
    window.setTimeout(() => $('#verify-account-cta').trigger('click'), 1000);
  };

  const handleIdvClick = () => {
    setIdvModalOpen(true);
  };

  useEffect(() => {
    loadOnboardingFlow();
    $(document).ajaxComplete((_event, _jqXHR, ajaxOptions) => {
      if (ajaxOptions.method === 'GET') {
        return;
      }
      return _debounce(reloadOnboardingFlow, 1000);
    });
    // This cookie is set during the final step of the signup process
    // to maintain state between separate React roots (dashboard and signup flow).
    if (Cookies.get('connect_bank_modal')) {
      window.setTimeout(openConnectBankModelOnFundingTab, 1000);
      Cookies.remove('connect_bank_modal');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DashboardBankAccountStore.on('bankAccount.create', reloadOnboardingFlow);
    DashboardBankAccountStore.on('bankAccount.verify', reloadOnboardingFlow);
    DashboardTransferStore.on('transfer.create', reloadOnboardingFlow);
    DashboardCardStore.on('cards.reloadOnboardingFlow', reloadOnboardingFlow);

    return () => {
      DashboardBankAccountStore.off('bankAccount.create', reloadOnboardingFlow);
      DashboardBankAccountStore.off('bankAccount.verify', reloadOnboardingFlow);
      DashboardTransferStore.off('transfer.create', reloadOnboardingFlow);
      DashboardCardStore.off('cards.reloadOnboardingFlow', reloadOnboardingFlow);
    };
  }, [reloadOnboardingFlow]);

  const getAbleActProgramFundingStepInfo = () => {
    const { cardBalance, programName } = props;

    if (cardBalance > 0) {
      return {
        bodyText: 'Congratulations! You have completed funding the card.',
        checkedClass: 'complete',
      };
    }

    const output = {
      checkedClass: 'incomplete',
    };
    if (programName === 'ABLE') {
      output.bodyText = (
        <span>
          You will be able to begin transferring money to your new ABLE Card 1 to 3 business days
          after you've ordered your card. Add funds to the card by logging into{' '}
          <a href="https://www.sumday.com/login" rel="noreferrer" target="_blank">
            sumday.com/login
          </a>{' '}
          and initiating a withdrawal directly to your prepaid card.
        </span>
      );
    } else if (programName === 'STABLE') {
      output.bodyText = (
        <span>
          You will be able to begin transferring money to your new STABLE Card 1 to 3 business days
          after you've ordered your card. Add funds to the card by logging into{' '}
          <a href="https://www.stableaccount.com" rel="noreferrer" target="_blank">
            stableaccount.com
          </a>{' '}
          and following the instructions.
        </span>
      );
    }
    return output;
  };

  const toggleActivateCardModal = (ev) => {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    setModalOpen((prevState) => !prevState);
  };

  const openActivateCardModal = (ev) => {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    if (!onboardingFlow.vccCard) {
      //need idv before we can activate the card
      onboardingFlow.idv ? setActivateModalOpen(true) : setIdvModalOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  const getMicroDepositsText = () => (
    <div className={classes.helperText}>
      We have sent two small deposits to your bank account, when you see them please enter the
      amounts here.
    </div>
  );

  const getIdvFailedText = () => (
    <div className={classes.helperText}>
      We need more information! Please email{' '}
      <a onClick={handleIdvClick} onKeyUp={handleIdvClick} role="link" tabIndex="0">
        secondary documents
      </a>{' '}
      to documents@truelinkfinancial.com.
    </div>
  );

  const activationLink = (
    <>
      Has the card arrived? <a id="activate-card-link">Activate here</a>
    </>
  );
  const pendingText = <>Activate the card once it arrives</>;

  const getStepInfo = (step) => {
    let propertyStatus;
    let pendingCard;
    const output = {};
    switch (step) {
      case 'bank-account':
        propertyStatus = onboardingFlow.bankAccountVerified || onboardingFlow.bankAccountAdded;
        output.bodyText = (
          <span>
            <a
              data-toggle="tab"
              href="#funding_tab"
              id="bank-account-link"
              onClick={propertyStatus ? noop : openConnectBankModelOnFundingTab}
            >
              Connect a bank account
            </a>
          </span>
        );
        break;
      case 'micro-deposits':
        propertyStatus = onboardingFlow.microDepositsSent && onboardingFlow.bankAccountVerified;
        output.bodyText = (
          <span>
            <a
              data-toggle="tab"
              href="#funding_tab"
              id="micro-deposits-link"
              onClick={propertyStatus ? noop : openVerifyAccountModelOnFundingTab}
            >
              Verify micro-deposits
            </a>
          </span>
        );
        break;
      case 'transfer':
        propertyStatus = onboardingFlow.transferScheduled || onboardingFlow.transferAmount;
        output.bodyText = (
          <span>
            <a
              data-toggle="tab"
              href="#funding_tab"
              id="transfer-link"
              onClick={propertyStatus ? noop : handleTransferLinkClick}
            >
              Schedule a transfer
            </a>
          </span>
        );
        break;
      case 'idv':
        propertyStatus = onboardingFlow.idv;
        output.bodyText = (
          <span>
            <a
              className={idvFailedFor18OrOver ? 'failed' : ''}
              id="idv-link"
              onClick={propertyStatus ? noop : handleIdvClick}
              onKeyUp={propertyStatus ? noop : handleIdvClick}
              role="link"
              tabIndex="0"
            >
              {idvFailedFor18OrOver ? 'Action Required: ' : ''} Verify the cardholder’s identity
            </a>
          </span>
        );
        break;
      case 'activate-card':
        pendingCard = !onboardingFlow.vccCard && !onboardingFlow.cardSlug;
        propertyStatus = onboardingFlow.cardActivated || onboardingFlow.cardOpen;
        output.bodyText = (
          <span
            onClick={propertyStatus || pendingCard ? noop : openActivateCardModal}
            onKeyUp={propertyStatus || pendingCard ? noop : openActivateCardModal}
            role="link"
            tabIndex="0"
          >
            {pendingCard ? pendingText : activationLink}
          </span>
        );
        break;
    }
    output.checkedClass = propertyStatus ? 'complete' : '';
    return output;
  };

  const closeActivateCardModal = () => {
    setModalOpen(false);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const handleActivationSuccess = () => {
    reloadOnboardingFlow();
    window.Truelink.flash('success', 'Activation successful!');
  };

  const getConfirmationPopupProps = () => ({
    dismissBtnText: 'Close',
    header: 'Success!',
    modalOpen: confirmationModalOpen,
    maxWidth: '960px',
    onClose: closeConfirmationModal,
  });

  const toggleConfirmationModal = (ev) => {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    setConfirmationModalOpen((prevState) => !prevState);
  };

  const onSuccess = (resp) => {
    $('#spinner, #overlay').hide();
    Truelink.flashClear();
    toggleConfirmationModal();
    toggleActivateCardModal();
    reloadOnboardingFlow();
    setAccountId(resp.account_id);
  };

  const closeWidget = () => {
    const url = getOnboardingFlowPath();
    const data = {
      data: {
        type: 'account_onboarding_flow',
        attributes: {
          bannerDismissed: Date.now().toString(),
        },
      },
    };

    axios
      .patch(url, data, AXIOS_XHR_CONFIG)
      .then(({ data }) => setOnboardingFlow(data?.data?.attributes));
  };

  const handleStepClick = (step, event) => {
    event.stopPropagation();
    event.preventDefault();
    const element = document.getElementById(`${step}-link`);
    if (element) {
      element.click();
    }
  };

  const renderStep = (step) => {
    const { cardProgramId, programName } = props;

    if (
      step === 'monitor' &&
      ([3, 4].includes(cardProgramId) || isAbleActProgramAccount(programName))
    ) {
      return null;
    }

    const { bodyText, checkedClass } =
      step === 'able-stable-funding' ? getAbleActProgramFundingStepInfo() : getStepInfo(step);

    return (
      <>
        <div
          className={classNames(classes.onboardingWidgetStep, `${step}`)}
          onClick={(event) => handleStepClick(step, event)}
          onKeyUp={(event) => handleStepClick(step, event)}
          role="link"
          tabIndex="0"
        >
          <div
            className={classNames(
              classes.onboardingWidgetStepCheck,
              `${checkedClass} onboarding_widget__step__check`,
            )}
            data-testid={step}
          />
          <div className={classNames(classes.onboardingWidgetStepText, `${checkedClass}`)}>
            <span style={{ marginRight: 15 }}>{bodyText}</span>
          </div>
        </div>
        {step === 'micro-deposits' && !onboardingFlow.bankAccountVerified && (
          <div>{getMicroDepositsText()}</div>
        )}
        {step === 'idv' && idvFailedFor18OrOver && <div>{getIdvFailedText()}</div>}
      </>
    );
  };

  const getFundingStepInfo = () => {
    if (isAbleActProgramAccount(props.programName)) {
      return <>{renderStep('able-stable-funding')}</>;
    }

    return (
      <>
        {renderStep('bank-account')}
        {onboardingFlow.bankAccountAdded && renderStep('micro-deposits')}
        {onboardingFlow.bankAccountVerified && renderStep('transfer')}
      </>
    );
  };

  const getWelcomeText = () => {
    const { programName } = props;

    if (isAbleActProgramAccount(programName)) {
      const article = getProgramNameArticle(programName);
      return `Thank you for signing up for ${article} ${programName} Card!`;
    }
    return 'Welcome to True Link!';
  };

  const getCloseBtn = () => (
    <TrueLinkButton
      className={classes.onboardingWidgetCloseBtn}
      id="close-onboarding-widget"
      label="close widget"
      onClick={closeWidget}
      variant="none"
    >
      ×
    </TrueLinkButton>
  );

  const getEstimatedArrivalText = () => {
    const { estimatedArrival } = props;

    if (estimatedArrival.status == PRE_RELEASE_STATUS) {
      return `Cards typically arrive within ${estimatedArrival.range.join(
        ' to ',
      )} business days. Estimated arrival is ${estimatedArrival.arrival_date}.`;
    } else if (estimatedArrival.status == RELEASED_STATUS) {
      return `Your card is on its way! It will arrive by ${estimatedArrival.arrival_date}.`;
    }
    return `The card should have arrived by ${estimatedArrival.arrival_date}. Please call ${props.supportADTollfree} if you have not yet received it.`;
  };

  if (shouldHideWidget()) return null;

  const onboardingWidgetContent =
    props.cardBalance > 0 && props.cardStatus === 'Not activated' ? (
      <TrueLinkNotification
        callToAction={() => setActivateModalOpen(true)}
        callToActionText={'Activate Card'}
        subTitle={getEstimatedArrivalText()}
        title={'Card Not Activated'}
      />
    ) : (
      <>
        {getCloseBtn()}
        <div className={classes.onboardingWidgetLeft}>
          <b>{getWelcomeText()}</b>
          <div>{getEstimatedArrivalText()}</div>
        </div>
        <div className={classes.onboardingWidgetRight}>
          <Card className={classes.onboardingWidgetCard}>
            <CardContent>
              {props.displayFundingTab && getFundingStepInfo()}
              {renderStep('idv')}
              {renderStep('activate-card')}
            </CardContent>
          </Card>
        </div>
      </>
    );

  return (
    <div id="onboarding-widget">
      <div className={classes.onboardingWidget}>
        <div className={classes.onboardingWidgetInner}>{onboardingWidgetContent}</div>
      </div>
      {modalOpen && (
        <DashboardCardActivationForm
          cardContext
          onClose={closeActivateCardModal}
          onSuccess={onSuccess}
        />
      )}
      {activateModalOpen && (
        <CardActivationModal
          accountId={props.accountId}
          cardSlug={onboardingFlow.cardSlug}
          firstName={props.cardholder.first_name}
          last4digits={onboardingFlow.last4CardDigits}
          lastName={props.cardholder.last_name}
          nickname={props.cardholder.nickname}
          onClose={() => setActivateModalOpen(false)}
          onSuccess={handleActivationSuccess}
        />
      )}
      {idvModalOpen && (
        <IdvModal
          firstName={props.cardholder.first_name}
          idvFailed={onboardingFlow.idvFailed}
          lastName={props.cardholder.last_name}
          onClose={() => setIdvModalOpen(false)}
          onSuccess={reloadOnboardingFlow}
          personId={onboardingFlow.personId}
          userSlug={props.userSlug}
        />
      )}
      {firstTransfeModalOpen && (
        <FirstTransferPromptModal
          modalOpen={firstTransfeModalOpen}
          onClose={() => setFirstTransfeModalOpen(false)}
        />
      )}
      <PopUp {...getConfirmationPopupProps()}>
        <BulkCardOrderConfirmationBox cardContext />
      </PopUp>
    </div>
  );
}

OnboardingWidget.propTypes = {
  userSlug: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  address: PropTypes.object,
  cardBalance: PropTypes.number.isRequired,
  cardStatus: PropTypes.string,
  cardholder: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    mobile: PropTypes.string,
    nickname: PropTypes.string,
    dob: PropTypes.string,
  }),
  cardProgramId: PropTypes.number,
  displayFundingTab: PropTypes.bool,
  programName: PropTypes.string.isRequired,
  supportADTollfree: PropTypes.string.isRequired,
  supportCHTollfree: PropTypes.string.isRequired,
  estimatedArrival: estimatedArrivalShape.isRequired,
  firstCardOrder: PropTypes.bool.isRequired,
  contactEmail: PropTypes.string.isRequired,
  msaLink: PropTypes.string,
  supportEmail: PropTypes.string,
  onChangeCardholderAttr: PropTypes.func,
};

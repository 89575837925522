import PropTypes from 'prop-types';
import React from 'react';
import TrashCan from 'images/trash-can.png';
import SavedMessage from 'react/shared/components/SavedMessage';
import { list } from 'react/shared/utils/RailsRoutes';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class FormActionBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDelete: true,
    };

    bindAll(this);
  }

  controller() {
    return this.props.controller;
  }

  model() {
    return this.controller().model();
  }

  editable() {
    return this.controller().editable();
  }

  changed() {
    return this.controller().changed();
  }

  is_new() {
    return this.controller().is_new();
  }

  saved(obj) {
    this.savedMessage.saved(obj);

    if (this.props.afterSave) {
      this.props.afterSave(obj);
    }
  }

  reset() {
    this.savedMessage.reset();
  }

  render() {
    const editable = this.editable();
    const changed = this.changed();
    const model = this.model();
    const actions = [];

    if (this.props.hideIndex != true) {
      const collection = this.props.collection || list(model)();
      actions.push(
        <span key="index-link">
          <a href={collection}>&lt; Index</a>
        </span>,
      );
    }

    if (this.props.extraActions) {
      actions.push(...this.props.extraActions);
    }

    const is_new = this.controller().is_new();
    const onDestroy = (evt) => {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure?')) {
        this.controller().destroy();
      } else {
        evt.stopPropagation();
      }
    };

    const saved = <SavedMessage ref={(input) => (this.savedMessage = input)} />;
    let destroy;
    if (!this.controller().is_new() || !this.editable()) {
      if (this.props.showEdit) {
        let showOrEditLabel;
        if (editable) {
          if (changed) {
            showOrEditLabel = 'Save';
          } else {
            showOrEditLabel = 'Cancel';
          }
        } else {
          showOrEditLabel = 'Edit';
        }
        actions.push(
          <span key="edit-link">
            <a href="#" onClick={this.controller().toggleEdit}>
              {showOrEditLabel}
            </a>
          </span>,
        );
      }
      if (this.props.showDelete) {
        destroy = (
          <div style={{ float: 'right' }}>
            <a href="#" onClick={onDestroy}>
              {}
              <img
                alt="Destroy"
                height={25}
                src={TrashCan}
                style={{ border: 'solid 1px silver', borderRadius: 3 }}
                width={25}
              />
            </a>
          </div>
        );
      }
    } else {
      actions.push(
        <span key="save-link">
          <a href="#" onClick={this.controller().toggleEdit}>
            Save
          </a>
        </span>,
      );
    }

    return (
      <div className="fright">
        {actions.map((action, index) => (
          <span key={action.key}>
            {action}
            {index < actions.length - 1 && <span> | </span>}
          </span>
        ))}
        {saved} {!is_new && destroy}
      </div>
    );
  }
}

FormActionBar.propTypes = {
  controller: PropTypes.object,
  collection: PropTypes.string,
  hideIndex: PropTypes.bool,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  afterSave: PropTypes.func,
  extraActions: PropTypes.array,
};

FormActionBar.defaultProps = {
  showEdit: true,
  hideIndex: false,
};
